<template>
  <TemplateFormComp />
</template>

<script>
import TemplateFormComp from "@/layouts/TemplateFormComp.vue";

export default {
  name: "TemplateForm",
  components: { TemplateFormComp },

};
</script>
